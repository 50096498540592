<template>
    <div class="content_container product_detail">
        <div class="inner">
            <div class="left_con">
                <h1>{{ this.productData.product_name }}</h1>

                <div class="image_wrap">
                    <span v-if="this.productData.product_image.length == 0">상품 이미지가 없습니다.</span>
                    <template v-else>
                        <div class="main_image">
                            <img
                                :src="this.main_image.path + this.main_image.save_name"
                                :alt="this.main_image.original_name"
                            />
                        </div>
                        <div class="image_list">
                            <div
                                v-for="(item, i) in this.productData.product_image"
                                :key="i"
                                @click="switchMainImage(i)"
                            >
                                <img :src="item.path + item.save_name" :alt="item.original_name" />
                            </div>
                        </div>
                    </template>
                </div>

                <div class="table_wrap">
                    <table>
                        <tr class="top">
                            <th>카테고리</th>
                            <td>{{ this.productData.category }}</td>
                            <th>모집마감일</th>
                            <td>{{ this.dateFormat(this.productData.finish_date) }}</td>
                        </tr>
                        <tr class="top">
                            <th>소비자가</th>
                            <td v-if="isConsumerExpose">
                                {{ this.numberFormat(this.productData.consumer_price) }}원
                            </td>
                            <td v-else>비공개</td>
                            <th>예상마진</th>
                            <td>{{ this.productData.margin }}</td>
                        </tr>
                        <tr class="top">
                            <th>공급가</th>
                            <td v-if="isSupplyExpose">
                                {{ this.numberFormat(this.productData.supply_price) }}원
                            </td>
                            <td v-else>
                                비공개
                                <button
                                    v-if="this.user.user_division == '판매자'"
                                    class="exposure common_button"
                                    :disabled="this.productData.pre_request_yn == 'true'"
                                    @click="openPopup('비공개')"
                                >
                                    {{ this.productData.pre_request_yn == 'true' ? '요청완료' : '공개요청'}}
                                </button>
                            </td>
                            <th>MOQ</th>
                            <td>{{ this.productData.moq }}</td>
                        </tr>
                        <tr class="top">
                            <th>제조기간</th>
                            <td>즉시공급(재고보유)</td>
                            <th>구입 가능 샘플 수량</th>
                            <td>{{ this.numberFormat(this.productData.quantity) }}개</td>
                        </tr>
                        <tr class="top">
                            <th>친환경 여부</th>
                            <td>일반상품</td>
                        </tr>

                        <tr class="top_m">
                            <th>카테고리</th>
                            <td>{{ this.productData.category }}</td>
                        </tr>
                        <tr class="top_m">
                            <th>모집마감일</th>
                            <td>{{ this.dateFormat(this.productData.finish_date) }}</td>
                        </tr>
                        <tr class="top_m">
                            <th>소비자가</th>
                            <td v-if="isConsumerExpose">
                                {{ this.numberFormat(this.productData.consumer_price) }}원
                            </td>
                            <td v-else>비공개</td>
                        </tr>
                        <tr class="top_m">
                            <th>예상마진</th>
                            <td>{{ this.productData.margin }}</td>
                        </tr>
                        <tr class="top_m">
                            <th>공급가</th>
                            <td v-if="isSupplyExpose">
                                {{ this.numberFormat(this.productData.supply_price) }}원
                            </td>
                            <td v-else>
                                비공개
                                <button
                                    v-if="this.user.user_division == '판매자'"
                                    class="exposure common_button"
                                    @click="openPopup('비공개')"
                                >
                                    공개요청
                                </button>
                            </td>
                        </tr>
                        <tr class="top_m">
                            <th>MOQ</th>
                            <td>{{ this.productData.moq }}</td>
                        </tr>
                        <tr class="top_m">
                            <th>제조기간</th>
                            <td>즉시공급(재고보유)</td>
                        </tr>
                        <tr class="top_m">
                            <th>구입 가능 샘플 수량</th>
                            <td>{{ this.numberFormat(this.productData.quantity) }}개</td>
                        </tr>
                        <tr class="top_m">
                            <th>친환경 여부</th>
                            <td>일반상품</td>
                        </tr>

                        <!--공급 상품 특징 넣기-->
                        <tr class="ways con">
                            <th>공급 상품 특징</th>
                            <td colspan="3">
                                <div class="wrap">
                                    <div class="way" v-for="(item, i) in this.productData.way" :key="i">
                                        {{ item.way }}
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr class="ways con">
                            <th>선호 공급방법</th>
                            <td colspan="3">
                                <div class="wrap">
                                    <div class="way" v-for="(item, i) in this.productData.way" :key="i">
                                        {{ item.way }}
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr class="sale con">
                            <th>선호 판매채널</th>
                            <td colspan="3">
                                <div class="wrap">
                                    <div class="channel" v-for="(item, i) in this.productData.channel" :key="i">
                                        {{ item.channel }}
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr class="sale con">
                            <th>주력 판매지역</th>
                            <td colspan="3">
                                <div class="wrap">
                                    <div class="place" v-for="(item, i) in this.productData.area" :key="i">
                                        {{ item.area }}
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr class="explain con">
                            <th>상품설명</th>
                            <td colspan="3">{{ this.productData.explanation }}</td>
                        </tr>

                        <tr class="explain con">
                            <th>해시태그</th>
                            <td colspan="3" v-if="this.productData.hashtag.length > 0">
                                <div class="hashtag">
                                    <div class="hashtag_word" v-for="(item, i) in this.productData.hashtag" :key="i">
                                        {{ item.content }}
                                    </div>
                                </div>
                            </td>
                            <td colspan="3" v-else>-</td>
                        </tr>

                        <tr class="file con">
                            <th>첨부파일</th>
                            <td colspan="3" v-if="this.productData.product_file.length > 0">
                                <div class="file_wrap">
                                    <a
                                        :href="
                                            this.productData.product_file[0].path +
                                            this.productData.product_file[0].save_name
                                        "
                                        >{{ this.productData.product_file[0].original_name }}</a
                                    >
                                    <img src="@/assets/images/common/file_upload.svg" alt="file_upload" />
                                </div>
                            </td>
                            <td v-else colspan="3">-</td>
                        </tr>
                    </table>
                </div>
            </div>

            <!--right_fixed 시작-->
            <div class="right_fixed">
                <div class="right_wrap">
                    <div class="top">
                        <span class="label_provider">
                            {{ this.productData.user_division }}
                        </span>
                        <router-link :to="`/provider/detail/${this.productData.id}`">
                            <p class="text_link">프로필 보기</p>
                        </router-link>
                    </div>
                    <div class="profile">
                        <img
                            v-if="this.productData.original_name"
                            :src="this.productData.path + this.productData.save_name"
                            :alt="this.productData.original_name"
                        />
                        <img v-else src="@/assets/images/common/charactor_01.png" alt="프로필사진" />
                    </div>
                    <div class="user_class" :class="this.productData.level_icon">
                        <p>{{ this.productData.level }}</p>
                    </div>
                    <h1>{{ this.productData.name }}</h1>

                    <div class="scale_wrap">
                        <div class="scale">
                            <h2>최고 입찰</h2>
                            <p>
                                <span>{{ this.numberFormat(this.productData.max_token) }}</span
                                >개
                            </p>
                        </div>
                        <div class="scale">
                            <h2>받은 제안</h2>
                            <p>
                                <span>{{ this.numberFormat(this.productData.request_count) }}</span
                                >건
                            </p>
                        </div>
                        <div class="scale">
                            <h2>지원인원</h2>
                            <p>
                                <span>{{ this.numberFormat(this.productData.request_user) }}</span
                                >명
                            </p>
                        </div>
                    </div>
                </div>
                <button
                    v-if="this.user.user_division == '판매자'"
                    type="button"
                    class="common_button"
                    @click="openPopup"
                >
                    {{ this.productData.request_token > 0 ? "추가입찰하기" : "제안하기" }}
                </button>
                <button
                    v-if="this.user.user_division == '판매자'"
                    type="button"
                    class="sub_button"
                    @click="openSamplePopup"
                >
                    샘플구매
                </button>

                <!--btn_left 누르면 옆에서 팝업 나타나기-->
                <div class="btn_left" @click="openRightFixed">
                    <span class="gt">&lt;</span>
                </div>
            </div>
            <!--right_fixed 끝-->
        </div>
    </div>
    <SellerToProduct
        v-if="showPopup"
        :which="which"
        :productData="productData"
        @closePopup="closePopup"
        @priceExposure="priceExposure"
        @skip-request="skipRequest()"
    />
    <BuySample
        v-if="samplePopup"
        :sampleData="sampleData"
        @close-sample-popup="closeSamplePopup"
        @sampleToPriceRequest="sampleToPriceRequest"
    />
</template>

<script>
import SellerToProduct from "@/components/Proposal/SellerToProduct";
import BuySample from "@/components/Proposal/BuySample";

import { fetchData } from "@/utils/fetchData";
import { numberFormat } from "@/utils/numberFormat";
import { dateFormat } from "@/utils/dateFormat";
import { mapState } from "vuex";

export default {
    components: {
        SellerToProduct,
        BuySample,
    },
    props: {
        id: String,
    },
    mixins: [fetchData, numberFormat, dateFormat],
    computed: {
        ...mapState("user", ["user"]),
        ...mapState("access_token", ["access_token"]),
        isConsumerExpose(){
            return this.productData.consumer_price_exposure_yn != '비공개' ||
            (this.productData.consumer_price_exposure_yn == '회원공개' && this.access_token)
        },
        isSupplyExpose(){
            return this.productData.supply_price_exposure_yn != '비공개' ||
            (this.productData.supply_price_exposure_yn == '회원공개' && this.access_token)
        }
    },
    data() {
        return {
            main_image: {},
            showPopup: false,
            samplePopup: false,
            which: "",

            productData: {
                category: [],
                channel: [],
                area: [],
                product_image: [],
                finish_date: "",
                consumer_price: "",
                supply_price: "",
                hashtag: [],
                product_file: [],

                // 등등
            },
            sampleData: {},
        };
    },
    mounted() {
        this.fetchProductData();
    },
    methods: {
        // 상품 데이터 불러오기
        fetchProductData() {
            this.fetchData(`/getProductDetail/${this.id}`, (data) => {
                this.productData = data.data;
                this.sampleData = {
                    ...data.data.sampleInfo,
                    product_name: data.data.product_name,
                    provider_name: data.data.name,
                    product_pk: data.data.product_pk,
                    price_exposure_yn: data.data.supply_price_exposure_yn,
                };
                this.switchMainImage(0);
            });
        },
        switchMainImage(i) {
            this.main_image = this.productData.product_image[i];
        },
        openRightFixed(event){
            event.currentTarget.classList.toggle('open');
            const right_fixed = document.querySelector('.right_fixed');
            right_fixed.classList.toggle('open');
        },
        // 팝업 열기
        openPopup(which) {
            this.showPopup = true;
            if (which == "비공개") {
                this.productData.isRequest = false;
                this.which = which;
            } else {
                if (this.productData.request_token > 0) {
                    this.which = "추가입찰";
                } else {
                    if (this.productData.supply_price_exposure_yn === "비공개" && this.productData.pre_request_yn == 'false') {
                        this.productData.isRequest = true;
                        this.which = "비공개";
                    } else {
                        this.productData.isRequest = false;
                        this.which = "공개";
                    }
                }
            }
        },
        // 공개요청
        priceExposure(){
            this.productData.pre_request_yn = 'true';
        },
        // 팝업 닫기
        closePopup() {
            this.showPopup = false;
        },
        openSamplePopup() {
            this.samplePopup = true;
        },
        closeSamplePopup() {
            this.samplePopup = false;
        },
        skipRequest() {
            this.closePopup();
            this.showPopup = true;
            this.which = "공개";
        },
        sampleToPriceRequest(){
            this.closeSamplePopup();
            this.showPopup = true;
            this.productData.isRequest = false;
            this.which = "비공개";
        }
    },
};
</script>

<style scoped src="@/assets/css/layout_product.css"></style>
