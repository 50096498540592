<template>
    <div>
        <div class="screen_filter"></div>
        <!-- 상품 팝업 시작 -->
        <div class="popup popup_proposal">
            <button type="button" @click="closePopup">
                <img src="@/assets/images/common/icon_close_w.svg" alt="close_w" />
            </button>

            <!--공급가요청 (비공개) 시작-->
            <div class="inner" v-if="this.which == '비공개'">
                <div class="product_title">
                    <h1>{{ this.productData.product_name }}</h1>
                </div>
                <div class="user user_provider">
                    <span>공급자</span>
                    <p>{{ this.productData.name }}</p>
                </div>

                <div class="product">
                    <div class="ask">
                        <div class="ask_icon">
                            <img src="@/assets/images/common/icon_price_w.svg" alt="ticket" />
                        </div>
                        <h1>공급가 요청</h1>
                        <p>공급자에게 공급가 공개 요청을 하시겠습니까?</p>
                    </div>
                    <div class="scale_wrap">
                        <div class="scale scale_ticket">
                            <p>최소 요청 토큰</p>
                            <h1>{{ this.numberFormat(this.productData.min_token) }}<span>개</span></h1>
                        </div>
                        <div class="scale scale_ticket">
                            <p>추가할 토큰</p>
                            <div class="add_ticket">
                                <button class="minus" @click="changeMetaToken('-', $event)">-</button>
                                <input type="text" :value="this.meta_token" @input="changeMetaToken('=', $event)" />
                                <button class="plus" @click="changeMetaToken('+', $event)">+</button>
                            </div>
                        </div>
                        <div class="scale scale_ticket">
                            <p>보유 토큰</p>
                            <h1>{{ this.numberFormat(this.user.meta_token) }}<span>개</span></h1>
                        </div>
                    </div>
                </div>

                <div class="last_btn_wrap">
                    <button type="button" id="exposureSubmit" class="common_button" @click="savePriceExposure" disabled>
                        <p>확인</p>
                    </button>
                    <button
                        v-if="productData.isRequest"
                        type="button"
                        class="sub_button"
                        @click="$emit('skip-request')"
                    >
                        <p>건너뛰기</p>
                    </button>
                    <button v-else type="button" class="sub_button" @click="closePopup">
                        <p>취소</p>
                    </button>
                </div>
            </div>
            <!--공급가요청 (비공개) 끝-->

            <!--제안하기 (공개) 시작-->
            <div class="inner" v-if="this.which == '공개'">
                <div class="product_title">
                    <h1>{{ this.productData.product_name }}</h1>
                </div>
                <div class="user user_provider">
                    <span>공급자</span>
                    <p>{{ this.productData.name }}</p>
                </div>

                <div class="product">
                    <div class="ask">
                        <div class="ask_icon">
                            <img src="@/assets/images/common/icon_token_w.svg" alt="token" />
                        </div>
                        <h1>제안하기</h1>
                        <p>토큰을 사용하여 거래를 선점하시겠습니까?</p>
                        <p class="group_buying">공동구매가 가능한 상품입니다.</p>
                    </div>

                    <div class="scale_wrap">
                        <div class="scale scale_ticket">
                            <p>최고 입찰 토큰</p>
                            <h1>{{ this.numberFormat(this.productData.max_token) }}<span>개</span></h1>
                        </div>
                        <div class="scale scale_ticket">
                            <p>최소 제안 토큰</p>
                            <h1>{{ this.numberFormat(this.productData.min_token) }}<span>개</span></h1>
                        </div>
                        <div class="scale scale_ticket">
                            <p>추가할 토큰</p>
                            <div class="add_ticket">
                                <button class="minus" @click="changeMetaToken('-', $event)">-</button>
                                <input type="text" :value="this.meta_token" @input="changeMetaToken('=', $event)" />
                                <button class="plus" @click="changeMetaToken('+', $event)">+</button>
                            </div>
                        </div>
                        <div class="scale scale_ticket">
                            <p>보유 토큰</p>
                            <h1>{{ this.numberFormat(this.user.meta_token) }}<span>개</span></h1>
                            <button class="sub_button" @click="$router.push('/mypage/MypageBuyToken')">
                                토큰 충전
                            </button>
                        </div>
                        <div class="btn_wrap">
                            <button class="sub_button" @click="$router.push('/mypage/MypageBuyToken')">
                                <p>토큰 충전</p>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="last_btn_wrap">
                    <button
                        id="requestSubmit"
                        type="button"
                        class="common_button"
                        @click="requestSellerProduct"
                        disabled
                    >
                        <p>확인</p>
                    </button>

                    <button type="button" class="sub_button" @click="closePopup">
                        <p>취소</p>
                    </button>
                    <button type="button" class="common_button" @click="this.$store.commit('alert', '준비중입니다.')">
                        <p>공동구매</p>
                    </button>
                </div>
            </div>
            <!--제안하기 (공개) 끝-->

            <!--추가입찰 팝업 시작-->
            <div class="inner" v-if="this.which == '추가입찰'">
                <div class="product_title">
                    <h1>{{ this.productData.product_name }}</h1>
                </div>
                <div class="user">
                    <span>공급자</span>
                    <p>{{ this.productData.provider_name }}</p>
                </div>

                <div class="product">
                    <div class="ask">
                        <div class="ask_icon">
                            <img src="@/assets/images/common/icon_token_w.svg" alt="token" />
                        </div>
                        <h1>추가 입찰</h1>
                        <p>토큰을 추가하여 입찰하시겠습니까?</p>
                    </div>
                    <div class="scale_wrap">
                        <div class="scale scale_ticket">
                            <p>최고 입찰 토큰</p>
                            <h1>{{ this.numberFormat(this.productData.max_token) }}<span>개</span></h1>
                        </div>
                        <div class="scale scale_ticket">
                            <p>입찰된 토큰</p>
                            <h1>{{ this.numberFormat(this.productData.request_token) }}<span>개</span></h1>
                        </div>
                        <div class="scale scale_ticket">
                            <p>추가할 토큰</p>
                            <div class="add_ticket">
                                <button class="minus" @click="changeMetaToken('-', $event)">-</button>
                                <input type="text" :value="this.meta_token" @input="changeMetaToken('=', $event)" />
                                <button class="plus" @click="changeMetaToken('+', $event)">+</button>
                            </div>
                        </div>
                        <div class="scale scale_ticket">
                            <p>보유 토큰</p>
                            <h1>{{ this.numberFormat(this.user.meta_token) }}<span>개</span></h1>
                        </div>
                    </div>
                </div>

                <div class="last_btn_wrap">
                    <button type="button" class="common_button">
                        <p>확인</p>
                    </button>

                    <button type="button" class="sub_button" @click="closePopup">
                        <p>취소</p>
                    </button>
                </div>
            </div>
            <!--추가입찰 팝업 끝-->
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import { postData } from "@/utils/postData";
import { numberFormat } from "@/utils/numberFormat";
import { changeMetaToken } from "@/utils/changeMetaToken";

export default {
    mixins: [postData, numberFormat, changeMetaToken],
    computed: {
        ...mapState("user", ["user"]),
    },
    props: {
        which: String,
        productData: Object,
    },
    watch: {
        meta_token(val) {
            if (this.which == "비공개") {
                if (val < this.productData.min_token) {
                    document.getElementById("exposureSubmit").disabled = true;
                } else {
                    document.getElementById("exposureSubmit").disabled = false;
                }
            } else if (this.which == "공개") {
                if (val < this.productData.min_token) {
                    document.getElementById("requestSubmit").disabled = true;
                } else {
                    document.getElementById("requestSubmit").disabled = false;
                }
            }
        },
    },
    data() {
        return {
            meta_token: 0,
        };
    },
    methods: {
        // 팝업 닫기
        closePopup() {
            this.$emit("closePopup");
        },
        // 상품 공급가 공개 요청
        savePriceExposure() {
            if (this.meta_token == 0 || this.meta_token < this.productData.min_token) {
                this.$store.commit("alert", "최소 요청 토큰 이상의 토큰을 입력해주세요");
                return;
            }
            this.postData(
                "/savePriceExposure",
                {
                    meta_token: this.meta_token,
                    product_pk: this.productData.product_pk,
                },
                () => {
                    this.$store.commit(
                        "alert",
                        "공개요청이 완료되었습니다. <br/><span class='tiny'>요청이 거부되면 토큰은 전액 반환됩니다.</span>",
                    );
                    this.$store.commit("user/metaTokenUse", this.meta_token);
                    this.$emit("priceExposure");
                    this.closePopup();
                },
            );
        },
        // 제안하기
        requestSellerProduct() {
            if (this.meta_token == 0 || this.meta_token < this.productData.min_token) {
                this.$store.commit("alert", "최소 제안 토큰 이상의 토큰을 입력해주세요");
                return;
            }
            this.postData(
                "/requestSellerProduct",
                {
                    meta_token: this.meta_token,
                    product_pk: this.productData.product_pk,
                },
                () => {
                    this.$store.commit(
                        "alert",
                        "제안이 완료되었습니다. <br/><span class='tiny'>거래가 성사되지 않으면 토큰은 전액 반환됩니다.</span>",
                    );
                    this.$store.commit("user/metaTokenUse", this.meta_token);
                    this.closePopup();
                },
            );
        },
    },
};
</script>

<style scoped src="@/assets/css/components/commonPopup.css"></style>
