<template>
    <div>
        <div class="screen_filter"></div>
        <!-- 상품 팝업 시작 -->
        <div class="popup popup_proposal">
            <button type="button" @click="closeSamplePopup">
                <img src="@/assets/images/common/icon_close_w.svg" alt="close_w" />
            </button>

            <!--샘플구매 (공개) 시작-->
            <div class="inner">
                <div class="popup_header">
                    <div class="title_container">
                        <div class="product_title">
                            <h1>{{ sampleData.product_name }}</h1>
                        </div>
                        <div class="user user_provider">
                            <span>공급자</span>
                            <p>{{ sampleData.provider_name }}</p>
                        </div>
                    </div>
                    <div class="account_container">
                        <span>무통장 입금</span>
                        <div class="account_textbox">
                            <div class="account">
                                <h1>은행 : {{ this.sampleData.bank }}</h1>
                            </div>
                            <div class="account">
                                <h1>예금주 : {{ this.sampleData.accholder }}</h1>
                            </div>
                            <div class="account">
                                <h1>계좌번호 : {{ this.sampleData.accnum }}</h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="product">
                    <div class="ask">
                        <div class="ask_icon">
                            <img src="@/assets/images/common/icon_token_w.svg" alt="token" />
                        </div>
                        <h1>샘플 구매 하기</h1>
                        <p>토큰을 사용하여 샘플을 구매 하시겠습니까?</p>
                    </div>

                    <div class="scale_wrap">
                        <div class="scale scale_ticket">
                            <p>구입 가능 샘플</p>
                            <h1>{{ this.numberFormat(Number(sampleData.quantity)) }}<span>개</span></h1>
                        </div>
                        <div class="scale scale_ticket">
                            <p>샘플 개당 가격</p>
                            <h1 v-if="purchase_way=='토큰'">
                                {{ !isExpose ? '비공개' : this.numberFormat(sampleData.token) }}<span v-if="isExpose">개</span>
                            </h1>
                            <h1 v-if="purchase_way=='무통장'">
                                {{ !isExpose ? '비공개' : this.numberFormat(sampleData.price) }}<span v-if="isExpose">원</span>
                            </h1>
                            <button v-if="!isExpose" class="common_button" @click="$emit('sampleToPriceRequest')">
                                공개요청
                            </button>
                        </div>
                        <div v-if="isExpose" class="scale scale_ticket">
                            <p>구매 할 샘플</p>
                            <div class="add_ticket">
                                <button class="minus" @click="changeSampleNum('-')">-</button>
                                <input type="number" v-model="sample_num" />
                                <button class="plus" @click="changeSampleNum('+')">+</button>
                            </div>
                        </div>
                        <div v-if="!isExpose" class="scale scale_ticket">
                            <p>총 구매 가격</p>
                            <h1>공개 요청 필요</h1>
                        </div>
                        <template v-else>
                            <div class="scale scale_ticket">
                                <p>{{ purchase_way=='토큰' ? '필요한 토큰' : '총 구매 가격'}}</p>
                                <h1>{{ this.numberFormat(sampleData.price * sample_num) }}<span>{{purchase_way!='토큰' ? '개' : '원'}}</span></h1>
                            </div>
                            <div v-if="purchase_way=='토큰'" class="scale scale_ticket">
                                <p>보유 토큰</p>
                                <h1>{{ this.numberFormat(this.user.meta_token) }}<span>개</span></h1>
                                <button class="sub_button" @click="$router.push('/mypage/MypageBuyToken')">
                                    토큰 충전
                                </button>
                            </div>
                            <div v-if="purchase_way=='토큰'" class="btn_wrap">
                                <button class="sub_button" @click="$router.push('/mypage/MypageBuyToken')">
                                    <p>토큰 충전</p>
                                </button>
                            </div>
                        </template>
                    </div>
                </div>
                <div class="purchase_way">
                    <input id="bank" type="radio" v-model="purchase_way" value="무통장" />
                    <label for="bank">무통장입금</label>
                    <input id="card" type="radio" v-model="purchase_way" value="토큰" />
                    <label for="card">토큰</label>
                </div>
                <div class="last_btn_wrap">
                    <button id="btn_buysample" type="button" class="common_button" @click="buySamples" disabled>
                        <p>확인</p>
                    </button>
                    <button type="button" class="sub_button" @click="closeSamplePopup">
                        <p>취소</p>
                    </button>
                </div>
            </div>
            <!--샘플구매 (공개) 끝-->
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

import { postData } from '@/utils/postData';
import { numberFormat } from '@/utils/numberFormat';

export default {
    mixins: [postData, numberFormat],
    props: {
        sampleData: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            sample_num: 0,
            //isBought: false,
            purchase_way: '무통장',
        };
    },
    computed: {
        ...mapState('ai', ['popup']),
        ...mapState('user', ['user']),
        ...mapState('access_token', ['access_token']),
        isExpose(){
            return this.sampleData.price_exposure_yn != '비공개' ||
            (this.sampleData.price_exposure_yn == '회원공개' && this.access_token)
        }
    },
    watch: {
        sample_num(newVal) {
            if (newVal === '') {
                this.sample_num = 0;
            } else if (newVal > Number(this.sampleData.quantity)) {
                this.sample_num = Number(this.sampleData.quantity);
            }
            if(!this.isExpose) {
                document.querySelector('#btn_buysample').disabled = true;
            } else if (newVal <= 0) {
                this.sample_num = 0;
                document.querySelector('#btn_buysample').disabled = true;
            } else {
                document.querySelector('#btn_buysample').disabled = false;
            }
        },
    },

    methods: {
        // 팝업 닫기
        closeSamplePopup() {
            this.$emit('close-sample-popup');
        },
        changeSampleNum(sign) {
            if (sign === '-' && this.sample_num > 0) {
                this.sample_num--;
            } else if (sign === '+' && Number(this.sampleData.quantity) > this.sample_num) {
                this.sample_num++;
            }
        },
        saveSample() {
            const formData = new FormData();
            // formData.append('access_token', this.access_token);
            formData.append('meta_token', this.sampleData.token * this.sample_num);
            formData.append('purchase_way', this.purchase_way);
            formData.append('product_pk', this.sampleData.product_pk);
            formData.append('price', this.sampleData.price * this.sample_num);

            this.postData('/saveSample', formData, () => {
                this.$store.commit('alert', '샘플 구매 요청이 완료되었습니다.');
                this.closeSamplePopup();
            });
        },
        buySamples() {
            if(!this.isExpose){
                this.$store.commit('alert', '샘플 가격 공개요청을 먼저 해주세요.');
                return;
            }
            if (this.purchase_way === '무통장') {
                this.saveSample();
            } else {
                if (this.user.meta_token >= this.sampleData.token) {
                    this.saveSample();
                } else {
                    this.$store.commit('alert', '보유 토큰 부족합니다.');
                }
            }
        },
    },
};
</script>
<style scoped src="@/assets/css/components/commonPopup.css"></style>

<style scoped>
.popup_header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--base-color1);
}
.account_container {
    display: flex;
}
.account_container span {
    margin-right: 2rem;
}
.account_textbox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.popup .inner .title_container {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}
.popup .inner .title_container .product_title {
    height: auto;
    justify-content: space-between;
}

.popup .inner .title_container .product_title h1 {
    font-size: var(--font-mid);
    font-weight: var(--font-w-mid);
    letter-spacing: var(--letter-spacing);
}
.popup .inner .title_container .user {
    display: flex;
    align-items: center;
    height: 50px;
    border-bottom: 0px;
}
.purchase_way {
    display: flex;
    justify-content: center;
}
.purchase_way input {
    margin: 1rem;
}
.purchase_way label {
    margin-right: 1rem;
}
</style>
